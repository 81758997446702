import React, { useState } from 'react';
import './header.css';
import logo from '../assets/conciergerie_start2grow_logo.png'
import flag_fr from '../assets/flag_fr.png'
import flag_en from '../assets/flag_en.png'
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const Header = () => {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const languageOptions = [
        { value: 'fr', label: t("header_fr"), image: flag_fr},
        { value: 'en', label: t("header_en"), image: flag_en}
      ];
    const isScreenMedium = window.matchMedia("(max-width: 900px)").matches;

    return (
        <header>
            <div className="header">
                <div className="header-logo">
                    <img src={logo} alt="logo" />
                    <h1>{t("header_title")}</h1>
                </div>
                <div className={`header-menu ${isMenuOpen ? 'open' : ''}`}>
                    <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <div className="menu-icon"></div>
                        <div className="menu-icon"></div>
                        <div className="menu-icon"></div>
                    </div>                    
                    <ul>
                        <li><Link className="link" to="/home">{t("header_accueil")}</Link></li>
                        <li><Link className="link" to="/appartements">{t("header_appartements")}</Link></li>
                        <li><Link className="link" to="/about-us">{t("header_a_propos")}</Link></li>
                        <li><Link className="link" to="/contact-us">{t("header_contact")}</Link></li>
                    </ul>
                    <Select
                        options={languageOptions}
                        isSearchable={false}
                        defaultValue={localStorage.getItem('language') === 'en' ? languageOptions[1] : localStorage.getItem('language') === 'fr' ? languageOptions[0] : languageOptions[0]}
                        styles={{
                            control: (styles) => ({ ...styles, backgroundColor: '#6ab0d8', borderRadius: '0.5rem', border: 'none', width: '80px', maxHeight: isScreenMedium ? '35px' : '38px', minHeight: isScreenMedium ? '25px' : '38px'}),
                            singleValue: (styles) => ({ ...styles, color: 'white' }),
                            option: (styles) => ({ ...styles, color: 'black',display: 'flex', justifyContent: 'center', alignItems: 'center'}),
                            dropdownIndicator: (styles) => ({ ...styles, color: 'white', '&:hover': { color: 'black' } }),
                            indicatorSeparator: (styles) => ({ ...styles, backgroundColor: 'white'}),
                            valueContainer: (styles) => ({ ...styles, padding: '0'}),
                        }}
                        formatOptionLabel={({ label, image }) => (
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}> 
                              <img src={image} alt={label} style={{width: '20px', marginRight: '10px'}} />
                            </div>    
                          )}
                        onChange={({ value }) => {
                            i18n.changeLanguage(value);
                            localStorage.setItem('language', value)
                        }}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;