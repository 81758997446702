import React from "react";

import "./Option.css";

const Options = (props) => {

  const options = props.options.map((option) => (
    <button className="option-button"  key={option.id !== undefined ? option.id : null} onClick={() => {
        props.actionProvider.handleOptionClick(option);
    }}>
      {option.text}
    </button>
  ));




return (
    <div>
        <div className="options-container">
                {options}
        </div>
    </div>

    );
};

export default Options;

