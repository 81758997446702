import React, { useEffect, useState, useMemo } from 'react';
import './appartements.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import AppartIcon from '../assets/appartement.png';
import Not_Found from '../assets/not_found.jpg';
import AgrandirIcon from '../assets/agrandir.png';
import CameraIcon from '../assets/camera.png';
import MarkerClusterGroup from 'react-leaflet-cluster';
import gifloading from '../assets/orange_circles.gif';
import { Helmet } from 'react-helmet-async';

const ApartementIcon = L.icon({
    iconUrl: AppartIcon,
    iconSize: [50, 50],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});



const filtreappart = (appart,value) => {

    let newAppartements;
    if (value === '1') {
        newAppartements = [...appart].sort((a, b) => a.price - b.price);
    } else {
        newAppartements = [...appart].sort((a, b) => b.price - a.price);
    }
    return newAppartements;
}

const filtreapparttype = (appart,value) => {
    let newAppartements;
    if (value === '1') {
        newAppartements = [...appart].filter(appart => appart.type === 'Louer');
    } else if (value === '2') {
        newAppartements = [...appart].filter(appart => appart.type === 'Acheter');
    } else {
        newAppartements = appart;
    }
    return newAppartements;
}


const useFilteredAppartements = (fromback, OptionPrice, OptionType, searchTerm) => {
    const filteredAppartements = useMemo(() => {
        let filtered = [...fromback];

        if (OptionPrice === '' || OptionPrice === '1') {
            filtered = filtreappart(filtered, OptionPrice);
        }

        if (OptionType === '1' || OptionType === '2') {
            filtered = filtreapparttype(filtered, OptionType);
        }

        if (searchTerm !== '') {
            filtered = filtered.filter(appartement => 
                (appartement.ville && appartement.ville.toLowerCase().includes(searchTerm.toLowerCase())) 
                || (appartement.adresse && appartement.adresse.toLowerCase().includes(searchTerm.toLowerCase()))
                || (appartement.titre && appartement.titre.toLowerCase().includes(searchTerm.toLowerCase()))
                
            );
        }

        return filtered;
    }, [fromback, OptionPrice, OptionType, searchTerm]);

    return filteredAppartements;
};

function getMediaUrl(filePath) {
    if (filePath === null || filePath === undefined) {
        console.error('URL is undefined');
        return;
    }

    const serverUrl = "https://api.conciergerielocationnantes.com"
    const imageExtensions = ['.png', '.jpg', '.jpeg'];
    const videoExtensions = ['.mp4', '.webm', '.ogg'];

    if (imageExtensions.some(ext => filePath.endsWith(ext))) {
        return <img src={`${serverUrl}/${filePath}`} alt='appartement' />;
    } else if (videoExtensions.some(ext => filePath.endsWith(ext))) {
        return <video src={`${serverUrl}/${filePath}`} alt='appartement' controls muted controlsList="nodownload  noremoteplayback nomute" />;
    }
}
const Appartements = () => {

    const [OptionPrice, setOptionPrice] = useState('');
    const [OptionType, setOptionType] = useState('');
    const [showNewSection, setShowNewSection] = useState(true);
    const [SelectedAppart, setSelectedAppart] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [fromback, setFromBack] = useState([{}]);
    const [isLoading, setIsLoading] = useState(true);
    
    //const [filteredAppartements, setFilteredAppartements] = useState(fromback);


    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
      };

      
    const goToPrevious = (length) => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? length : prevIndex - 1));
     console.log(currentImageIndex);
    };

    const goToNext = (length) => {
    setCurrentImageIndex((prevIndex) => (prevIndex === length ? 0 : prevIndex + 1));
    };

    const clearfiltre = () => {
        setOptionPrice('');
        setOptionType('');
    };

    useEffect(() => {

        setIsLoading(true);

        fetch('https://api.conciergerielocationnantes.com/api/getappartements')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setFromBack(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                setIsLoading(false);
            });
    }, []);

    const filteredAppartements = useFilteredAppartements(fromback, OptionPrice, OptionType, searchTerm);

    const LoadingIndicator = () => (
        <div className="loading-icon">
            <img className="loading-icon-gif" src={gifloading} alt="loading" />
        </div>
    );

    return (
        <div>
            {isLoading ? <LoadingIndicator /> : null}

        <div className='MainContainer'>
            <Helmet>
                <title>Appartements</title>
                <meta name="description" content="Liste des appartements disponibles à la location ou à la vente." />
                <link rel="canonical" href="https://conciergerielocationnantes.com/appartements" />
            </Helmet>

            <MapContainer center={[47.2184, -1.5536]} zoom={12}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup>
                    {filteredAppartements.map(appartement => (
                        <Marker
                            key={appartement.id}
                            position={appartement && appartement.coordinates ? appartement.coordinates : [0, 0]}
                            icon={ApartementIcon}
                            eventHandlers={{
                                click: () => {
                                    setShowNewSection(false);
                                    setSelectedAppart(appartement);
                                },
                                mouseover: (e) => {
                                    e.target.openPopup();
                                },
                                mouseout: (e) => {
                                    e.target.closePopup();
                                }
                            }}
                        >
                            <Popup>
                                {appartement.titre} - {appartement.price} €<br/>
                                {appartement.adresse}<br/>
                                {appartement.code_postal} - {appartement.ville}
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </MapContainer> 
            {showNewSection ? (
            <div className="Menu-Appart">
                <div className='Menu-Appart-Toolbars'>
                    <div className='Menu-Appart-Toolbars-first'>
                        <input className='Search-Appart' type='text' placeholder='Rechercher...' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                    </div>
                    <div className='Menu-Appart-Toolbars-second'>
                        <select value={OptionType} onChange={e => setOptionType(e.target.value)}>
                            <option value='1'>Louer</option>
                            <option value='2'>Acheter</option>
                            <option value=''>Tous</option>
 
                        </select>
                        <select value={OptionPrice} onChange={e => setOptionPrice(e.target.value)}>
                            <option value=''>Prix croissant</option>
                            <option value='1'>Prix décroissant</option>
                        </select>
                        <button onClick={clearfiltre}>Effacer</button>

                     </div>   
                </div>

                <div className='Menu-Appart-List-Container'> 
                    <div className='Menu-Appart-List'>

                        {filteredAppartements.map(appartement => (
                            <div key={appartement.id} className='Menu-Appart-List-Item' onClick={() => {setShowNewSection(false); setSelectedAppart(appartement);}}>
                                <div className='Menu-Appart-List-Item-Img'>
                                    {appartement.images && appartement.images.length > 0 ? getMediaUrl(appartement.images[0]) : <img  src={Not_Found} alt='appartement' />}                            
                                </div>
                                <div className='Menu-Appart-List-Item-Content'>
                                    <h3>{appartement.titre + ', ' + appartement.type}</h3>
                                    <p>{appartement.adresse + ', ' + appartement.ville}</p>
                                    <p>{appartement.price} €</p>

                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
            ) : (
                <div className='Menu-Information-Appart'>
                    <div className='Menu-Information-Appart-Button-back'>
                        <button  onClick={() => {setShowNewSection(true); setSelectedAppart(null);}}><span className='Arrow-Back-Btn'>&lt;</span><span className='Text-Back-Btn'>Retour</span></button>
                    </div>

                    <div className='Menu-Information-Appart-Content'>
                        <div className='Menu-Information-Appart-Title'>
                            <div className='Menu-Information-Appart-Title-Info'>
                                <h2>{SelectedAppart.titre}<span className='Title-type'>, {SelectedAppart.type}</span></h2>
                                <span className='Contact-Info'>Contactez-nous au<br/>  07 45 17 46 30 ou 06 52 71 76 43</span>
                          
                            </div>
                            <h4>{SelectedAppart.adresse + ', ' + SelectedAppart.ville}</h4>
                            <p>{SelectedAppart.price} €</p>
                        </div>
                    <div className='Carousel-Container'>
                        <div className={`carousel ${isFullScreen ? 'full-screen' : ''}`}>
                            {isFullScreen ? ( (<div className='Full-Screen-Info'><h3>{SelectedAppart.title + ', ' +SelectedAppart.price+'€ ' }</h3></div>) ): ('')}
                            <button className="arrow left" onClick={ () => goToPrevious(SelectedAppart.images.length - 1)}>&lt;</button>
                            {SelectedAppart && SelectedAppart.images && getMediaUrl(SelectedAppart.images[currentImageIndex])} 
                            <button className="arrow right" onClick={ () => goToNext(SelectedAppart.images.length - 1)}>&gt;</button>
                            <button className="full-screen-button" onClick={toggleFullScreen}>
                                {isFullScreen ? "X" : <img src={AgrandirIcon} alt="error"></img>}
                            </button>
                            <div className='Count-Images'>
                                <h5>{SelectedAppart.images.length}</h5>
                                <img src={CameraIcon} alt="error"></img>
                            </div>
                        </div>
                    </div>
                    <div className='Menu-Information-Description'>
                        <h2>Description</h2>
                        <p>{SelectedAppart.description}</p>
                        <br/>
                        <h2>Surface</h2>
                            <ul>
                                <li>Surface habitable : {SelectedAppart.surface_habitable} m²</li>
                                <li>Surface boutin : {SelectedAppart.surface_boutin} m²</li>
                            </ul>
                        <br/>
                        <h2>Caractéristiques</h2>
                            <ul>
                                <li>Nombre d'étages  {SelectedAppart.caracteristiques[0].nb_etages}</li>
                                <li>Nombre de pièces  {SelectedAppart.caracteristiques[0].nb_pieces}</li>
                                <li>Nombre de chambres  {SelectedAppart.caracteristiques[0].nb_chambres}</li>
                                <li>Nombre de salle d'eau  {SelectedAppart.caracteristiques[0].nb_salle_deau}</li>
                                <li>Nombre de WC  {SelectedAppart.caracteristiques[0].nb_wc}</li>
                                <li>Cuisine  {SelectedAppart.caracteristiques[0].cuisine}</li>
                                <li>Assainissement  {SelectedAppart.caracteristiques[0].assainissement}</li>
                                <li>Chauffage  {SelectedAppart.caracteristiques[0].chauffage}</li>
                            </ul>
                        <br/>

                        <h2>Informations</h2>
                            <ul>
                                <li>Loyer CC {SelectedAppart.information[0].loyer} € / {SelectedAppart.information[0].charges}€ de Charges</li>
                                <li>Dépôt de garantie {SelectedAppart.information[0].garantie} €</li>
                                <li>Meublé {SelectedAppart.information[0].meuble === 1 ? 'oui' : 'non'}</li>
                                <li>Cave {SelectedAppart.information[0].cave === 1 ? 'oui' : 'non'}</li>
                                <li>Parking {SelectedAppart.information[0].parking === 1 ? 'oui' : 'non'}</li>
                            </ul>
                    </div>

                </div>
                </div>
            )}

        </div>
        </div>
    );
};

export default Appartements;
