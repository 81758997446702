import React from "react";

import "./BotAvatar.css";

const BotAvatar = () => {
    return (
        <div className="bot-avatar">
            M
        </div>
    );
}

export default BotAvatar;