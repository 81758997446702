import React from 'react';
import { Helmet } from 'react-helmet-async';

const NoPage = () => {
    return (
        <div style={{ textAlign: 'center', minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Helmet>
                <title>Page non trouvée</title>
                <meta name="description" content="Page non trouvée" />
                <link rel="canonical" href="https://conciergerielocationnantes.com/404" />
            </Helmet>
            <h1 style={{fontSize:"100px"}}>Error 404</h1>
            <p style={{fontSize:"30px"}}>Page not found</p>
        </div>
    );
};

export default NoPage;