import { createChatBotMessage } from "react-chatbot-kit";
import BotAvatar from "./BotAvatar";
import Options from "./widget/Option";
import ContactUs from "./widget/Contact-us";
import BackPMenu from "./widget/BackPMenu";

const config = {
  botName: "Assistant Start2Grow",
  lang: "fr",
  initialMessages: [createChatBotMessage(`Bonjour, je suis l'assistant de Start2Grow. Comment puis-je vous aider aujourd'hui ?`, {
    widget: "options",
  })],
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
    header: () => <div style={{backgroundColor: "purple", color: "white", paddingTop: "10px", paddingBottom: "10px", textAlign: "center", width : "100%"}}>Assistant Start2Grow</div>,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "purple",
    },
    chatButton: {
      backgroundColor: "purple",
    },
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème d'eau", id: 1, key : "probleme-eau-" },
          { text: "Problèmes électriques", id: 2, key : "probleme-electrique-" },
          { text: "Equipements", id: 3, key : "probleme-equipements-" },
          { text: "Voisinage", id: 4, key : "probleme-voisinage-" },
          { text: "Hygiene", id: 5, key : "probleme-hygiene-" },
          { text: "Autre", id: 6, key : "probleme-autre-" },
        ]
      }
    },
    // Problèmes d'eau :
    {
      widgetName: "probleme-eau-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Fuite au niveau des joints ou des raccords ?", id: 1 , key: "probleme-eau-1-" },
          { text: "Fuite au niveau du chauffe-eau ?", id: 2 , key: "probleme-eau-1-"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "La fuite est-elle au niveau des joints ?", id: 1 , key: "probleme-eau-1-1-" },
          { text: "La fuite est-elle au niveau des raccords de plomberie ?", id: 2 , key: "probleme-eau-1-1-"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-1-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous ferons appelle à un professionnel."
        />
      ),
    },
    {
      widgetName: "probleme-eau-1-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous ferons appelle à un professionnel."
        />
      ),
    },
    {
      widgetName: "probleme-eau-1-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Fuite au niveau du chauffage-eau ?", id: 1 , key: "probleme-eau-2-" },
          { text: "Problème de ballon d'eau chaude à l'arrêt ?", id: 2 , key: "probleme-eau-2-",Title: "Avez-vous une panne d’électricité ?"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-2-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Coupez l’alimentation du chauffe-eau et contactez-nous. Nous ferons appelle à un professionnel."
        />
      ),
    },
    {
      widgetName: "probleme-eau-2-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-eau-2-2-" },
          { text: "Non", id: 2 , key: "probleme-eau-2-2-", Title:" Le problème est-il lié au thermostat ?"},
        ],
      }
    },
    {
      widgetName: "probleme-eau-2-2-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous ferons appelle à un professionnel."
        />
      ),
    },
    {
      widgetName: "probleme-eau-2-2-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-eau-2-2-2-", Title:" Avez-vous coupé puis rallumé le conteur électrique ?"},
          { text: "Non", id: 2 , key: "probleme-eau-2-2-2-"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-2-2-2-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-eau-2-2-2-1-",Title:" Le ballon d’eau chaude a-t-il redémarré ?" },
          { text: "Non", id: 2 , key: "probleme-eau-2-2-2-"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-2-2-2-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-eau-2-2-2-1-1-",Title:" Problème résolu ?" },
          { text: "Non", id: 2 , key: "probleme-eau-2-2-2-"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-2-2-2-1-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-eau-2-2-2-1-1-1-" },
          { text: "Non", id: 2 , key: "probleme-eau-2-2-2-"},
        ]
      }
    },
    {
      widgetName: "probleme-eau-2-2-2-1-1-1-1",
      widgetFunc: (props) => <BackPMenu {...props} />
    },
    {
      widgetName: "probleme-eau-2-2-2-1-1-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous ferons appelle à un professionnel."
        />
      ),
    },
    {
      widgetName: "probleme-eau-2-2-2-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous ferons appelle à un professionnel."
        />
      ),
    },
    // Problèmes électriques :
    {
      widgetName: "probleme-electrique-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème de disjoncteur ?", id: 1 , key: "probleme-electrique-2-",Title: "Le disjoncteur saute-t-il régulièrement ?" },
          { text: "Retour au menu principal",key: "back"},
        ]
      }
    },
    {
      widgetName: "probleme-electrique-2-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-electrique-2-1-"},
          { text: "Non", id: 2 , key: "probleme-electrique-2-1-"},
        ],
      }
    },
    {
      widgetName: "probleme-electrique-2-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous ferons appelle à un professionnel."
        />
      ),
    },
    {
      widgetName: "probleme-electrique-2-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Allez voir votre disjoncteur et remettez le courant en marche. Si le problème devient récurrent, veuillez nous contacter."
        />
      ),
    },
    // Equipements :
    {
      widgetName: "probleme-equipements-3",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème sur les équipements du logement ?", id: 1 , key: "probleme-equipements-3-" },
          { text: "Détecteur de fumée ?", id: 2 , key: "probleme-equipements-3-"},
          { text: "Une porte ou une fenêtre est défectueuse ?", id: 3 , key: "probleme-equipements-3-"},
          { text: "Vous avez perdu une ou plusieurs clés ?",key: "probleme-equipements-3-", id: 4 },
        ]
      }
    },
    {
      widgetName: "probleme-equipements-3-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-1-", Title: "L'électroménager dysfonctionne ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-3-1-"},
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-1-1",
      widgetFunc: (props) =>  <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-1-1-"},
          { text: "Non", id: 2 , key: "probleme-equipements-3-1-1-", Title:"Manque-t-il un équipement ?" },
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-1-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="prenez une vidéo et envoyez-la nous via le compte Instagram comme preuve, nous ferons le nécessaire. Si le problème aurait pu être évité vous serez facturé"
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-1-1-2",
      widgetFunc: (props) => < Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-1-1-2-"},
          { text: "Non", id: 2 , key: "probleme-equipements-3-1-"},
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-1-1-2-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="prenez une vidéo et envoyez-la nous via le compte Instagram comme preuve, nous ferons le nécessaire. Si c’est après l’état des lieux il vous sera factur"
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="aucun problème"
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-2-", Title: "Problème détecteur de fumée ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-3-2-"},
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-2-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-2-1-", Title: "Le détecteur de fumée est-il en panne ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-3-2-"},
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-2-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-2-1-1-", Title: "Problème résolu ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-3-2-1-1-" },
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-2-1-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", key: "back" },
          { text: "Non", id: 2 , key: "probleme-equipements-3-2-1-1-"},
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-2-1-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter nous viendrons voir cela."
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-2-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter, nous viendrons en installer un."
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-3",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-3-", Title: "C’est une porte ?" },
          { text: "Retour au menu principal", key: "back" },
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-3-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-3-1-", Title: "La porte est-elle cassée ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-3-3-1-", Title: "C’est une fenêtre ?" },
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-3-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Envoyez-nous une photo via le compte Instagram. Si le problème aurait pu être évité vous serez facturé"
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-3-1-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-3-1-2-" },
          { text: "Retour au menu principal", key: "back" },
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-3-1-2-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Envoyez-nous une photo via le compte Instagram. Si c’est après l’état des lieux il vous sera facturé"
        />
      ),
    },

    {
      widgetName: "probleme-equipements-3-4",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-3-4-"},
          { text: "Non", id: 2 , key: "probleme-equipements-3-4-"},
        ],
      }
    },
    {
      widgetName: "probleme-equipements-3-4-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Contactez-nous, nous en referons et vous serez facturés"
        />
      ),
    },
    {
      widgetName: "probleme-equipements-3-4-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Aucun  problème"
        />
      ),
    },
    // Voisinage :
    {
      widgetName: "probleme-equipements-4",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème de voisinage ?", id: 1 , key: "probleme-equipements-4-", Title:"Est-ce à propos du bruit ?" },
          { text: "Retour au menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-equipements-4-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-4-1-", Title:"Contactez le voisin et échanger ensemble sur la situation ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-4-1-", Title:" Est-ce un conflit ?" },
        ]
      }
    },
    {
      widgetName: "probleme-equipements-4-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-4-1-1-", Title:"Problème résolu ?" },
          { text: "Non", id: 2 , key: "probleme-equipements-4-1-1-" },
        ]
      }
    },
    {
      widgetName: "probleme-equipements-4-1-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-equipements-4-1-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Contactez-nous, nous ferons notre maximum avec le voisin et le syndic pour régler la situation."
        />
      ),
    },
    {
      widgetName: "probleme-equipements-4-1-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-4-1-2-", Title:"Essayez d’échanger avec le voisin pour régler ce désaccord Problème résolu ?" },
          { text:"Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-equipements-4-1-2-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-equipements-4-1-2-1-"},
          { text: "Non", id: 2 , key: "probleme-equipements-4-1-2-1-"},
        ]
      }
    },
    {
      widgetName: "probleme-equipements-4-1-2-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-equipements-4-1-2-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Contactez-nous, nous ferons notre maximum avec le voisin et le syndic pour régler la situation."
        />
      ),
    },

    // Hygiene :

    {
      widgetName: "probleme-hygiene-5",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème d’insalubrité ?", id: 1 , key: "probleme-hygiene-5-", Title:"Parties communes sales ?" },
          { text: "Retour au menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-hygiene-5-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-hygiene-5-1-" },
          { text: "Non", id: 2 , key: "probleme-hygiene-5-1-", Title:"Problème de nuisible (rongeur, insecte…) ?" },
        ]
      }
    },
    {
      widgetName: "probleme-hygiene-5-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Contactez le syndic, une fiche avec les coordonnées est présente dans les parties communes et indiquez le problème."
        />
      ),
    },
    {
      widgetName: "probleme-hygiene-5-1-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-hygiene-5-1-2-" },
          { text: "Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-hygiene-5-1-2-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Contactez le syndic, une fiche avec les coordonnées est présente dans les parties communes et indiquez le problème."
        />
      ),
    },

    // Autre :


    {
      widgetName: "probleme-autre-6",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Evier bouché ? l’eau ne s’évacue pas ?", id: 1 , key: "probleme-autre-6-", Title:"Mettre de l’eau bouillante dans la canalisation et Acheter une ventouse déboucheur" },
          { text: "une coupure d’électricité ? ", id: 2 , key: "probleme-autre-6-" },
          { text: "Fuite d’eau ?" , id: 3 , key: "probleme-autre-6-" },
          { text: "une ampoule est défectueuse ?", id: 4 , key: "probleme-autre-6-" },
          { text: "Problème internet?", id: 5 , key: "probleme-autre-6-" },
          { text: "Menu principal", key: "back"}

        ]
      }
    },
    {
      widgetName: "probleme-autre-6-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-1-", Title:"le problème est résolu ?" },
          { text: "Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-1-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-1-1-" },
          { text: "Non", id: 2 , key: "probleme-autre-6-1-1-", Title:"Enlever le siphon et regarder si il y a un bouchon de cheveux par exemple et nettoyer " },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-1-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-1-1-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème résolu ? Oui", id: 1 , key: "probleme-autre-6-1-1-2-" },
          { text: "Problème résolu ? Non", id: 2 , key: "probleme-autre-6-1-1-2-" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-1-1-2-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-1-1-2-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Faire une vidéo et nous l’envoyer via notre compte instagram, nous allons contacter un artisan si le problème aurait pu être évité, une facture vous sera envoyé. ASTUCE : Pour éviter que le problème revienne, nettoyer les canalisations une fois par semaine avec du vinaigre blanc et du sel"
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-2",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-2-", Title:"Regarder sur le tableau électrique situé dans le logement. Si un disjoncteur est en position basse, il faut le remonter." },
          { text: "Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-2-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème résolu ? Oui", id: 1 , key: "probleme-autre-6-2-1-" },
          { text: "Problème résolu ? Non", id: 2 , key: "probleme-autre-6-2-1-" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-2-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-2-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Nous envoyer une photo pour preuve , nous allons contacter un artisan si le problème aurait pu être éviter, une facture vous sera envoyé."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-3",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-3-", Title:"Le compteur d’eau se trouve sur le palier, il faut tourner le robinet en position fermé pour stopper l’eau." },
          { text: "Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-3-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème résolu ? Oui", id: 1 , key: "probleme-autre-6-3-1-" },
          { text: "Problème résolu ? Non", id: 2 , key: "probleme-autre-6-3-1-" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-3-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-3-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Nous contacter immédiatement pour éviter d’autres dégâts, nous allons contacter un artisan."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-4",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-4-", Title:"Il faut la changer et bien la visser, Ampoule défectueuse après l’état des lieux?" },
          { text: "Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-4-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-4-1-" },
          { text: "Non", id: 2 , key: "probleme-autre-6-4-1-" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-4-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Achat à vos frais "
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-4-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Déduction sur le prochain loyer"
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-5",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Oui", id: 1 , key: "probleme-autre-6-5-", Title:"Appeler l’opérateur (le nom est noté sur la box internet) Orange/SFR/Bouygues Télécom" },
          { text: "Menu principal", key: "back" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-5-1",
      widgetFunc: (props) => <Options {...props} />,
      props: {
        options: [
          { text: "Problème résolu ? Oui", id: 1 , key: "probleme-autre-6-5-1-" },
          { text: "Problème résolu ? Non", id: 2 , key: "probleme-autre-6-5-1-" },
        ]
      }
    },
    {
      widgetName: "probleme-autre-6-5-1-1",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Veuillez nous contacter si le problème persiste."
        />
      ),
    },
    {
      widgetName: "probleme-autre-6-5-1-2",
      widgetFunc: (props) => (
        <ContactUs
          {...props}
          Text="Merci de nous contacter pour que nous puissions vous aider via instagram."
        />
      ),
    },


    {
      widgetName: "back",
      widgetFunc: (props) => <BackPMenu {...props} />
    }
  ]
}

export default config;


