import React, { useState, useEffect } from 'react';
import './home.css';
import { Helmet } from 'react-helmet-async';

const images = [
    require('../assets/banniere/1.png'),
    require('../assets/banniere/2.png'),
    require('../assets/banniere/3.png'),
    require('../assets/banniere/4.png'),
    require('../assets/banniere/5.png'),
    require('../assets/banniere/6.png'),
];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  function GoogleWidget() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.setAttribute("data-use-service-core", "");
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return <div className="elfsight-app-4de53c19-b6c1-4d6d-8555-41d686e9b37c" data-elfsight-app-lazy></div>;
  }

  function InstagramFeed() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.setAttribute("data-use-service-core", "");
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }

    }, []);

    return <div class="elfsight-app-3149d5bb-d68b-46be-b9a7-9789ac830a25" data-elfsight-app-lazy></div>
  }



  return (
    <div className="Home">
      <Helmet>
        <title>Accueil - Start To Grow</title>
        <meta name="description" content="Conciergerie qui permet de trouver un appartement ou une maison à louer." />
        <link rel="canonical" href="https://conciergerielocationnantes.com/" />
      </Helmet>
      <div className="slideshow-container">
        {images.map((image, index) => (
          <div key={index} className="mySlides" style={{ display: index === currentIndex ? 'block' : 'none' }}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
        <div className="dots-container">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className="Information-content">

        <div className="Information-content-text">
          <h1>Start To Grow</h1>
          <p>Start To Grow la conciergerie d'appartements et de maisons. Nous vous proposons des services de conciergerie pour vous accompagner dans la gestion de votre bien immobilier.</p>
        </div>
        <div className="InstagramFeed">
          {InstagramFeed()}
        </div>
      </div>

      <div className='google-reviews'>
        {GoogleWidget()}
      </div>
    </div>
  );
};

export default Home;