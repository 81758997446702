import React from 'react';
import './footer.css';
import icon_LinkedIn from '../assets/reseau/linkedln_icon.png';
import icon_Facebook from '../assets/reseau/facebook.png';
import icon_Twitter from '../assets/reseau/twitter.png';
import icon_Instagram from '../assets/reseau/instagram.png';
import icon_YouTube from '../assets/reseau/youtube.png';
import icon_Map from '../assets/reseau/map.png';
import icon_Tiktok from '../assets/reseau/tiktok.png';

import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    let Navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-social">
                    <h3>{t("footer_reseaux")}</h3>
                    <ul>
                        <li><a href="https://www.linkedin.com/company/start-2-grow" target="_blank" rel="noreferrer"><img src={icon_LinkedIn} alt="LinkedIn" /></a></li>
                        <li><a href="https://www.facebook.com/Start-To-Grow-101313571419187" target="_blank" rel="noreferrer"><img src={icon_Facebook} alt="Facebook" /></a></li>
                        <li><a href="https://x.com/STARTTOGROW1" target="_blank" rel="noreferrer"><img src={icon_Twitter} alt="Twitter" /></a></li>
                        <li><a href="https://www.instagram.com/start_2grow/?hl=fr" target="_blank" rel="noreferrer"><img src={icon_Instagram} alt="Instagram" /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCUS4pYN_c79p02recARuiCQ/featured" target="_blank" rel="noreferrer"><img src={icon_YouTube} alt="YouTube" /></a></li>
                        <li><a href="https://www.google.fr/maps/place/START+TO+GROW+l%E2%80%99investissement+locatif+cl%C3%A9+en+main/@48.8562493,2.3750967,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66d6a8d586f4d:0xa93b1c378d27eb10!8m2!3d48.8562493!4d2.3750967!16s%2Fg%2F11hq214w7l?entry=ttu" target="_blank" rel="noreferrer"><img src={icon_Map} alt="Map" /></a></li>
                        <li><a href="https://www.tiktok.com/@start_2grow?lang=fr" target="_blank" rel="noreferrer"><img src={icon_Tiktok} alt="Tiktok" /></a></li>

                    </ul>
                </div>
                <div className="footer-copyright">
                    <span className='footer-link' onClick={() => Navigate('/conditions-generales')}><u>{t("footer_conditions")}</u></span>
                    <br/>
                    <span>{t("footer_copyright")}</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;