
class ActionProvider {
    constructor(
      createChatBotMessage,
      setStateFunc,
      createClientMessage,
      stateRef,
      createCustomMessage,
      ...rest
    ) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
      this.createClientMessage = createClientMessage;
      this.stateRef = stateRef;
      this.createCustomMessage = createCustomMessage;
      this.history = [];
    }
    handleOptionClick = (option) => {
      const title = option.Title;

      this.history.push(option);
      console.log("history", this.history);
      if (title != null && title !== "") {
        const message = this.createChatBotMessage(`Vous avez choisi: ${option.text}. Que souhaitez-vous faire ensuite ?`,{
        });

        this.setState((state) => ({
          ...state,
          messages: [...state.messages, message],
        }));

        const message2 = this.createChatBotMessage(title, {
          widget: `${option.key}${option.id !== undefined  ? option.id : ""}`
        });

        this.setState((state) => ({
          ...state,
          messages: [...state.messages, message2],
        }));
      }
      else {
        const message = this.createChatBotMessage(`Vous avez choisi: ${option.text}. Que souhaitez-vous faire ensuite ?`,{
          widget: `${option.key}${option.id !== undefined  ? option.id : ""}`
        });
        this.setState((state) => ({
          ...state,
          messages: [...state.messages, message],
        }));
      }
    }

    // handleBackMenuClick = () => {
    //   if (this.history.length <= 1) {
    //     const message = this.createChatBotMessage("Vous êtes déjà au début.");
    //     this.setState((state) => ({
    //       ...state,
    //       messages: [...state.messages, message],
    //     }));
    //     return;
    //   }
  
    //   // Remove the current state
    //   this.history.pop();
    //   // Get the last state
    //   const last = this.history[this.history.length - 1];
    //   console.log("history back", this.history);
  
    //   const message = this.createChatBotMessage(`Retour au menu précédent`, {
    //     widget: `${last.key}${last.id !== undefined ? last.id : ""}`
    //   });
  
    //   this.setState((state) => ({
    //     ...state,
    //     messages: [...state.messages, message],
    //   }));
    // }

    handleDefaultMenuClick = () => {
      const message = this.createChatBotMessage(`Menu principal :`, {
          widget: `options`
      });
      this.setState((prev) => ({
          ...prev,
          messages: [message]
      }));
    }
  
    handleYesNoClick = (answer) => {
      const message = this.createChatBotMessage(`Vous avez répondu: ${answer.text}`);
      this.setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message]
      }));
    }
    

    setChatbotMessage = (message) => {
        this.setState((state) => ({
            ...state,
            messages: [...state.messages, message]
        }));
    }
 }
 
 export default ActionProvider;