import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

import "./contact-us.css";


function ContactUs() {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [numero, setNumero] = useState('');
    const [objet, setObjet] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email || !numero || !objet || !message) {
            alert('Veuillez remplir tous les champs');
            return;
        }

        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
            alert('Adresse email invalide');
            return;
        }

        if (!/^[0-9]{8,14}$/.test(numero)) {
            alert('Numéro de téléphone invalide');
            return;
        }


        axios.post('https://api.conciergerielocationnantes.com/api/mail', { email, phone: numero, objet, message })
            .then((res) => {
                console.log(res.data);
                if (res.data.status) {
                    alert('mail envoyé avec succès');
                    setEmail('');
                    setNumero('');
                    setObjet('');
                    setMessage('');
                } else {
                    alert('Erreur lors de l\'envoi du message');
                }
            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi du message:', error);
                alert('Erreur lors de l\'envoi du message');
            });
    };

    return (
        <div className="contact">
            <Helmet>
                <title>Contactez-nous</title>
                <meta name="description" content="Contactez-nous pour toute demande de renseignements." />
                <link rel="canonical" href="https://conciergerielocationnantes.com/contact-us" />
            </Helmet>
            <h2 className="contact-title">{t('contact_titre')}</h2>

            <form className='form-contact' onSubmit={handleSubmit}>

                <div className='form-group-email-number'>
                    <div className="form-group">
                        <label>{t('contact_email')}</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>{t('contact_telephone')}</label>
                        <input type="text" value={numero} onChange={(e) => setNumero(e.target.value)} />
                    </div>
                </div>
                <div className='form-group-object'>
                    <div className="form-group">
                            <label>{t('contact_objet')}</label>
                            <input type="text" value={objet} onChange={(e) => setObjet(e.target.value)} />
                    </div>
                </div>

                <div className="form-group-message">
                    <div className="form-group">
                        <label>{t('contact_message')}</label>
                        <textarea className='textarea-message' value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>

                </div>
                <button className='btn-contact' type='submit'>{t('contact_valider')}</button>

            </form>
        </div>
    );
}

export default ContactUs;