
import React, { useState } from 'react';
import { Chatbot } from 'react-chatbot-kit';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import iconHelpDesk from '../../assets/Help_desk.png';

import 'react-chatbot-kit/build/main.css';
import './chatbot.css';




const ChatBot = () => {
    const [isShowChatBot, setIsShowChatBot] = useState(false);

    const handleChatBotClick = () => {
        setIsShowChatBot(!isShowChatBot);
    };

    return (
        <div className="chatbot-main-container">
            {isShowChatBot && (
                <div className="chatbot-container">
                    <h1>ChatBot</h1>
                    <Chatbot
                        config={config}
                        messageParser={MessageParser}
                        actionProvider={ActionProvider}
                    />
                </div>
            )}
            <div className="chatbot-button-container">
                <button className="chatbot-button" onClick={handleChatBotClick}>
                    <img className='chatbot-icon' src={iconHelpDesk} alt='chatbot-icon' />
                </button>
            </div>
        </div>
    );
}

export default ChatBot;