import React from "react";
import { Helmet } from 'react-helmet-async';


import './conditions-generales.css';

const ConditionsGenerales = () => {
    return (
        <div class="conditions-generales-container">
            <Helmet>
                <title>Conditions Générales d'Utilisation</title>
                <meta name="description" content="Conditions Générales d'Utilisation du site Start To Grow" />
                <link rel="canonical" href="https://conciergerielocationnantes.com/conditions-generales" />
            </Helmet>
        <h1>Conditions Générales d'Utilisation</h1>
        
        <h2>PRÉAMBULE</h2>
        <p>Partant du constat que la recherche d’un bien immobilier est souvent longue et fastidieuse, START TO GROW, a décidé de mettre son temps et son expérience au service des futurs acheteurs immobiliers en leur proposant un accompagnement sur mesure au travers d’un ensemble de services. L’utilisation du site est subordonnée au respect des présentes Conditions Générales d’Utilisation. Toute personne souhaitant y accéder doit en avoir pris connaissance préalablement et s’engage à les respecter.</p>
        
        <h2>DÉFINITIONS</h2>
        <p><strong>Client :</strong> Toute personne ayant décidé de confier la recherche de leur bien et de financement à Start to Grow, au travers d’un Mandat acheteur.</p>
        <p><strong>Mandat acheteur :</strong> Mandat conclu entre le client et Start to Grow, en vue de l’acquisition d’un bien immobilier.</p>
        <p><strong>Service :</strong> Désigne l’ensemble des prestations proposées au client par Start to Grow.</p>
        <p><strong>Utilisateurs :</strong> Toute personne accédant au Site, client ou simple internaute.</p>
        
        <h2>MENTIONS LÉGALES</h2>
        <p><strong>Editeur du site :</strong> SAS START TO GROW, société par actions simplifiée</p>
        <p><strong>Capital social :</strong> 500 €</p>
        <p><strong>Siège social :</strong> 53 bis rue de la roquette 75011 PARIS</p>
        <p><strong>RCS PARIS :</strong> 880 967 427</p>
        <p><strong>Nom commercial :</strong> START TO GROW</p>
        <p><strong>Directeur de la publication :</strong> Maxime TOUMOULIN</p>
        
        <h2>MENTIONS LÉGALES DISPOSITIF PINEL</h2>
        <p>Le dispositif dit PINEL est codifié à l'article 199 novovicies du Code Général des Impôts, il est réservé aux contribuables domiciliés en France qui acquièrent avant le 31 décembre 2021, un logement neuf dans une résidence devant faire l'objet d'une labellisation BBC EFFINERGIE 2005 ou construite conformément à réglementation thermique dite RT 2012, destiné à être loué nu à titre de résidence principale à des locataires présentant un niveau de ressources plafonné et moyennant des montants de loyers également plafonnés. Le logement doit être situé en zone dite tendue (zone A, A BIS et B1). Les logements neufs situés en zone B2 et C restent éligibles (toutes les autres conditions étant par ailleurs remplies) dès lors que le permis de construire a été déposé avant le 31 décembre 2018 et l’acte de vente définitif signé avant le 31 décembre 2019.</p>
        <p>Le dispositif PINEL permet de bénéficier d'une réduction d'impôts de 12% si l’engagement de location est pris pour six ans ou de 18% si cet engagement est pris pour 9 ans. L’engagement de location peut être prorogé pour atteindre une durée de 12 ans. La prorogation de l’engagement de location ouvrant droit à une nouvelle réduction d’impôts. Le pourcentage de réduction s’apprécie par rapport au coût d'acquisition dans la limite d'un montant de 300 000 € et dans la limite d'un prix de 5 500 €/m². Le contrat de location doit être signé dans les 12 mois suivant la date d'achèvement de l'immeuble ou de son acquisition si elle est postérieure. L'éligibilité au dispositif de réduction d'impôt dépend du lieu de situation du logement. En outre les logements acquis à compter du 1er janvier 2015 peuvent être donnés en location à des ascendants et des descendants. La réduction d’impôt est accordée au titre de l'année d'achèvement du logement, ou de son acquisition si elle est postérieure, et imputée dans un premier temps sur l'impôt dû au titre de cette même année, puis sur l'impôt dû au titre de chacune des années suivant la période de son engagement de location à raison d’une fraction (1/6e ou1/9e) de son montant total au titre de chacune de ces années, sans report possible. Le bénéfice de cette incitation fiscale entre dans le calcul du plafonnement global de certains avantages fiscaux prévu à l’article 200-0 A du CGI.</p>
        <p>Comme toute acquisition immobilière, réaliser un investissement locatif suppose d’apporter une attention particulière notamment de s’assurer de votre capacité d’emprunt. Par ailleurs et pour calculer la rentabilité escomptée d’un investissement immobilier, il convient de prendre en compte le contexte fiscal (se renseigner sur les taxes locales applicables dont la taxe foncière, sur votre régime fiscal concernant les revenus locatifs et le cas échéant l’ISF …), le contexte économique (vérifier le niveau des loyers pratiqués dans le secteur qui peuvent être inférieurs au plafond des loyers du dispositif PINEL, et plus généralement tenir compte des intérêts d’emprunt, des charges de copropriétés non récupérables, du coût de l’assurance propriétaire non occupant, des éventuels frais de gestion …).</p>
        <p>Le non-respect des engagements de location entraîne la perte du bénéfice des incitations fiscales. L’attention de l’acquéreur est attirée sur les risques afférents à un investissement locatif (ex. : non-respect des conditions d’octroi de l’incitation fiscale, y compris des conditions de mise en location, absence de locataire, loyers impayés, évolution défavorable du marché locatif) susceptibles d’entraîner la perte du bénéfice des incitations fiscales et/ou la remise en cause de l’équilibre financier de l’investissement.</p>
        
        <h2>MENTIONS LÉGALES DISPOSITIF CENSI BOUVARD</h2>
        <p>Le dispositif Censi-Bouvard est un dispositif de réduction d'impôts tel que prévu à l'article 199 sexvicies du Code Général des Impôts réservé aux contribuables domiciliés en France qui acquièrent en France jusqu'au 31 décembre 2019, un logement neuf compris dans certaines résidences services et qui s'engagent à le donner en location dans le cadre d’un bail commercial à l'exploitant de la résidence pour une période de 9 ans minimum. Le taux de la réduction d'impôt est de 11%, la réduction est calculée sur le coût d'acquisition (prix de vente HT + frais d'acquisition) du logement dans la limite de 300 000 €. Le bénéfice de cette incitation fiscale entre dans le calcul du plafonnement global de certains avantages fiscaux prévu à l’article 200-0 A du CGI. La location doit prendre effet dans le mois qui suit la date d’achèvement de l'immeuble ou de son acquisition si elle est postérieure, pour les logements acquis neuf ou en l'état futur d'achèvement. Comme toute acquisition immobilière, réaliser un investissement locatif suppose d’apporter une attention particulière notamment de s’assurer de votre capacité d’emprunt.</p>
        <p>Par ailleurs et pour calculer la rentabilité escomptée d’un investissement immobilier, il convient de prendre en compte le contexte fiscal (se renseigner sur les taxes locales applicables dont la taxe foncière, sur votre régime fiscal concernant les revenus tirés de la location qui seront des BIC et non des revenus fonciers, et le cas échéant l’ISF …), et plus généralement tenir compte des intérêts d’emprunt, des charges de copropriétés non récupérables, du coût de l’assurance propriétaire non occupant, des éventuels frais de gestion …. En outre cet investissement qui permet de récupérer la TVA nécessite d’établir des déclarations fiscales particulières, il est donc conseillé de s’adjoindre un expert-comptable à cet effet. Pour conserver le bénéfice de la récupération de TVA le bien doit être loué à un exploitant pendant 20 ans, à défaut de régularisation, la TVA doit être remboursée au prorata temporis.</p>
        <p>Le non-respect des engagements de location entraîne la perte du bénéfice des incitations fiscales. L’attention de l’acquéreur est attirée sur les risques afférents à un investissement locatif (ex : non-respect des conditions d’octroi de l’incitation fiscale, y compris des conditions de mise en location, absence de locataire, loyers impayés, évolution défavorable du marché locatif) susceptibles d’entraîner la perte du bénéfice des incitations fiscales et/ou la remise en cause de l’équilibre financier de l’investissement.</p>
    

        <h2>MENTIONS LÉGALES DU PRÊT À TAUX ZÉRO 2019</h2>

        <p>Le Prêt à taux zéro est un prêt réglementé qui vous permet de financer l’acquisition de votre première résidence principale, qui peut être :</p>
        <ul>
            <li>soit un logement neuf ou assimilé neuf selon les "normes thermiques en vigueur",</li>
            <li>soit un logement ancien avec travaux importants (dont le montant doit être toujours au moins égal à 25 % du coût total de l’opération) ou dans le cadre de la vente de logements anciens du parc social.</li>
        </ul>
        <p>Le Prêt à taux zéro est accessible sous conditions de ressources, aux personnes physiques désirant devenir propriétaire de leur première résidence principale (ne pas avoir été propriétaire de sa résidence principale au cours des 2 années précédant la nouvelle acquisition). Le montant et la durée du Prêt à Taux 0% varient en fonction de la zone géographique du logement et de la composition du foyer. Les ressources de l’acquéreur sont également prises en compte pour la durée du remboursement.</p>

        <h2>DESCRIPTION GENERALE DU SERVICE</h2>

        <p>START TO GROW, propose aux utilisateurs du Site une étude, sans engagement, de leur projet immobilier.</p>
        <p>Un conseiller en investissement immobilier prend alors contact avec les acquéreurs ayant rempli le formulaire afin d’étudier la faisabilité du projet et de l’ajuster en fonction de la réalité du marché et des capacités financières de l’acquéreur.</p>
        <p>Si l’utilisateur souhaite confier sa recherche de bien et de financement à START TO GROW, , il lui sera demandé de signer un Mandat acheteur.</p>
    
        <h2>DONNEES PERSONNELLES</h2>
        <p>Dans une logique de respect de la vie privée de ses Utilisateurs, Start to Grow, s’engage à ce que la collecte et le traitement d’informations personnelles, effectués au sein du présent Site, soient traités conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés.</p>
        <p>START TO GROW, garantit aux Utilisateurs un droit d’accès, de modification, de rectification et de suppression des données les concernant à tout moment et sans motif. Ce droit peut être exercé, sur simple demande, à l’adresse suivante : maxime@start2grow.fr</p>
        <p>START TO GROW, ne communiquera pas vos informations personnelles à des tiers. Toutefois, dans le cadre de sa mission de recherche de bien immobilier, et uniquement suite à la signature d’un contrat de mandat, il est expressément autorisé à communiquer les informations personnelles du client à toute personne, professionnel ou particulier, qu’il jugera utile pour l’accomplissement de sa mission.</p>
        <p>Données personnelles – CNIL</p>
        <p>L’utilisateur est invité à se référer à la Charte de protection des données personnelles.</p>

        <h2 style={{ textDecoration: 'underline' }}>RESPONSABILITE</h2>

        <h2>CONTENU DU SITE</h2>

        <p>Start to Grow, s’engage à fournir ses meilleurs efforts pour que les informations contenues dans le Site soient correctes et fiables.</p>
        <p>Toutefois, des erreurs éventuelles étant toujours possibles, Start to Grow, ne serait être tenus responsable des erreurs dans les informations contenues dans le Site ainsi que des éventuels dommages en découlant.</p>
        <p>Dès lors, Start to Grow, se réserve la possibilité de modifier le contenu du Site à sa seule convenance, à tout moment et sans avertissement préalable.</p>
    
        <h2>ACCÈS AU SITE</h2>

        <p>Le Site est accessible 24h/24h et 7 jours/7. Toutefois, Start to Growne pourra être tenu pour responsable, ou considéré comme ayant failli aux présentes conditions, pour tout retard ou inexécution, lorsque la cause du retard ou de l’inexécution est liée à un cas de force majeure telle que définie par la jurisprudence des cours et tribunaux français y compris l’interruption, la suspension, la réduction ou les dérangements de l’électricité ou autres ou toutes interruptions de réseaux de télécommunications.</p>
        <p>L’utilisateur du présent Site reconnaît avoir la compétence et les moyens notamment techniques nécessaires pour accéder à ce Site, et avoir vérifié que la configuration informatique utilisée ne contient aucun virus et qu’elle est en parfait état de fonctionnement</p>
        <p>Il appartient à l’utilisateur de s’assurer que les matériels informatiques dont il dispose, notamment ses logiciels de navigation sur Internet et/ou ses moyens de connexions (susceptible d’évolution) sont compatibles avec une utilisation efficiente du Service proposé par Start to Grow.</p>
        <p>Start to Grow, se réserve la possibilité de rendre le Site ou les services momentanément indisponibles pour des raisons de maintenance ou de procédures d’actualisations.</p>


        <h2>RESPONSABILITÉ LIÉE À L’UTILISATION DU SERVICE</h2>
        <p>Start to Grow, est soumis à une obligation de moyens quant au bon fonctionnement du Service. En aucun cas, il ne pourra être tenu responsable de dommages directs ou indirects liés à l’utilisation du Service, tels que la perte de chance, le préjudice pouvant découler de l’achat du bien….</p>

        <h2>LIAISON INTERNET ET TRANSFERT DE DONNÉES</h2>
        <p>Start to Grow ne pourra être tenu pour responsable de la qualité de la liaison Internet de l’Utilisateur. Lors du transfert de données par moyen de télécommunication ou par tous autres moyens, aucune responsabilité ne pourra être retenue contre Start to Grow, en cas d’altération des informations ou des données durant le transfert. Start to Grow, ne saurait être tenu responsable de dommages résultant de la perte, de l’altération ou de toute utilisation frauduleuse de données, de la transmission accidentelle de virus ou autres éléments nuisibles, de l’attitude ou comportement d’un tiers.</p>

        <h2>AUTRES LIMITATIONS DE RESPONSABILITÉ</h2>
        <p>En aucun cas START TO GROW, ne pourra voir sa responsabilité engagée en cas de rupture de la confidentialité ou de l’utilisation des données diffusées sur le Site, qui ne serait pas de son fait.</p>
        <p>En aucun cas START TO GROW, ne saurait être tenu responsable au titre des droits de Propriété intellectuelle qui ne seraient pas respectés par le Client.</p>
        <p>START TO GROW, ne peut être tenu responsable d’éventuels dysfonctionnements sur le matériel informatique du Client à la suite de l’utilisation du Service.</p>

        <h2>FORCE MAJEURE</h2>
        <p>En aucun cas START TO GROW, ne pourra être tenu responsable du dysfonctionnement du service en cas de force majeure, telle que définie par l’article 1148 du Code civil et la jurisprudence y afférent comme tout évènement, imprévisible, irrésistible et extérieur à la volonté des parties rendant impossible l’exécution de l’obligation.</p>
        <p>L’expression « Force Majeure » se rapporte à des circonstances dues à des événements naturels non prévisibles par START TO GROW, et qui ne peuvent être raisonnablement évités. L’expression Force Majeure, telle que définie dans le cadre des présentes, recouvre, sans limitation, les événements suivants : inondations, incendies et autres phénomènes naturels, faits de guerre, opérations militaires, actes de terrorisme, situations d’insécurité locale ou nationale rendant impossible l’exécution du Service, actes ou actions législatives du gouvernement et autres circonstances indépendantes de la volonté de START TO GROW.</p>
        <p>En cas de persistance du cas de force majeure au-delà d’une durée de trois (3) mois, les parties pourront librement choisir de mettre fin au contrat et ce, sans pouvoir réclamer d’indemnités, à quelque titre que ce soit.</p>
        <p>START TO GROW, ne pourra accepter aucune réclamation relative à une inexécution ou mauvaise exécution du Service qui serait imputable soit au Client, soit au fait d’un tiers étranger à la fourniture du Service, soit à un cas de Force Majeure.</p>

        <h2>MANQUEMENTS DE START TO GROW</h2>
        <p>En cas de manquement de START TO GROW, à ses obligations, telle que mentionnée aux présentes, le Client devra porter à sa connaissance le ou les dysfonctionnements constatés rendant impossible l’utilisation du Site, au moyen d’un email décrivant lesdits dysfonctionnements.</p>

        <h2>PROPRIETE INTELLECTUELLE</h2>
        <p>Chacun des éléments, en ce compris la présentation, l’arborescence, les textes, les représentations iconographiques et photographiques, les bases de données du site, les marques, logos et noms de domaine, apparaissant sur le Site, sont protégés par les lois en vigueur sur la propriété intellectuelle, et appartiennent à START TO GROW, ou font l’objet d’une autorisation d’utilisation.</p>
        <p>Aucun élément composant le Site ne peut être copié, reproduit, modifié, réédité, chargé, dénaturé, transmis ou distribué de quelque manière que ce soit, sous quelque support que ce soit, de façon partielle ou intégrale, sans l’autorisation écrite et préalable de START TO GROW, à l’exception d’une stricte utilisation pour les besoins de la presse et sous réserve du respect des droits de propriété intellectuelle et de tout autre droit de propriété dont il est fait mention.</p>
        <p>Toute représentation totale ou partielle du Site par quelque procédé que ce soit, non autorisée préalablement par START TO GROW, constituerait une contrefaçon au sens des articles L.335-2 et suivants du Code de la propriété intellectuelle. Seule la copie à usage privé est autorisée pour un usage personnel, privé et non-commercial.</p>

        <h2>MODIFICATION DES CONDITIONS GENERALES D’UTILISATION</h2>
        <p>Les présentes Conditions Générales d’Utilisation sont susceptibles d’être modifiées à tout moment par START TO GROW, sous réserve d’un préavis de trente (30) jours à partir de l’information faite au Client, par email.</p>
        <p>Le client qui refuse les modifications peut résilier le service dans ce délai de trente (30) jours à compter de l’information, sans aucune indemnité. Dans ce cas, les anciennes CGU resteraient applicables entre les parties dans l’attente de la résiliation. La résiliation du contrat prive immédiatement le client de l’accès au service, sans aucune indemnité.</p>

        <h2>DROIT APPLICABLE – ATTRIBUTION DE COMPETENCE</h2>
        <p>Les présentes sont soumises au droit français. Tout litige survenant à l’occasion de l’exécution et/ou de l’interprétation des présentes relèvera de la compétence des tribunaux de PARIS.</p>

        <h2>ADRESSE IP ET « COOKIES »</h2>
        <p>Les cookies sont des petits fichiers texte déposés sur votre ordinateur lors de la visite d’un site ou de la consultation d’une publicité. Ils ont notamment pour but de collecter des informations relatives à votre navigation sur les sites et de vous adresser des services personnalisés. Dans votre ordinateur les cookies sont gérés par votre navigateur internet (voir « 4-Vos choix concernant les cookies »). Les Cookies que nous émettons sur notre site Lorsque vous vous connectez à notre site, nous pouvons être amenés à installer divers cookies dans votre terminal nous permettant de reconnaître le navigateur de votre terminal pendant la durée de validité du cookie concerné.</p>

        <h2>CES COOKIES NOUS PERMETTENT</h2>
        <ul>
            <li>d’établir des statistiques et volumes de fréquentation et d’utilisation des diverses éléments composant notre site (rubriques et contenus visités, parcours), Nous permettant d’améliorer l’intérêt et l’ergonomie de nos services ;</li>
            <li>d’adapter la présentation de notre site aux préférences d’affichage de votre terminal (langue utilisée, résolution d’affichage, système d’exploitation utilisé, etc.) lors de vos visites sur notre Site, selon les matériels et les logiciels de visualisation ou de lecture que votre terminal comporte;</li>
            <li>de mémoriser des informations relatives à un formulaire que vous avez rempli sur notre site (inscription ou accès à votre compte) ou à des produits, services ou informations que vous avez choisis sur notre site (service souscrit, contenu d’un panier de commande, etc.) ;</li>
            <li>de vous permettre d’accéder à des espaces réservés et personnels de notre site, tels que votre compte, grâce à des identifiants ou des données que vous Nous avez éventuellement antérieurement confiés ;</li>
            <li>de mettre en œuvre des mesures de sécurité, par exemple lorsqu’il vous est demandé de vous connecter à nouveau à un contenu ou à un service après un certain laps de temps.</li>
        </ul>
        <p>De plus, certains cookies utilisés avec votre accord, nous permettent de vous proposer des services de discussion et une aide en ligne.</p>
        <p>Si vous partagez l’utilisation de votre terminal avec d’autres personnes Nous ne pouvons pas nous assurer de manière certaine que les services et publicités destinés à votre terminal correspondent bien à votre propre utilisation de ce terminal et non à celle d’un autre utilisateur de ce terminal. Le partage avec d’autres personnes de l’utilisation de votre terminal et la configuration des paramètres de votre navigateur à l’égard des cookies, relèvent de votre libre choix et de votre responsabilité.</p>

        <h2>VOS CHOIX CONCERNANT LES COOKIES</h2>
        <p>Plusieurs possibilités vous sont offertes pour gérer les cookies. Tout paramétrage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d’accès à certains services nécessitant l’utilisation de cookies. Vous pouvez faire le choix à tout moment d’exprimer et de modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous. Les choix qui vous sont offerts par votre logiciel de navigation Vous pouvez configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. Vous pouvez également configurer votre logiciel de navigation de manière à ce que l’acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu’un cookie soit susceptible d’être enregistré dans votre terminal. Pour plus d’informations, consultez la rubrique « Comment exercer vos choix, selon le navigateur que vous utilisez ? »</p>
    
        <h2>L’ACCORD SUR LES COOKIES</h2>
        <p>L’enregistrement d’un cookie dans un terminal est essentiellement subordonné à la volonté de l’utilisateur du terminal, que celui-ci peut exprimer et modifier à tout moment et gratuitement à travers les choix qui lui sont offerts par son logiciel de navigation. Si vous avez accepté dans votre logiciel de navigation l’enregistrement de cookies dans votre terminal, les cookies intégrés dans les pages et contenus que vous avez consultés pourront être stockés temporairement dans un espace dédié de votre terminal. Ils y seront lisibles uniquement par leur émetteur.</p>

        <h2>LE REFUS DES COOKIES</h2>
        <p>Si vous refusez l’enregistrement dans votre terminal des cookies que nous émettons, ou si vous supprimez ceux que nous y avons enregistrés, vous ne pourrez plus bénéficier d’un certain nombre de fonctionnalités qui sont néanmoins nécessaires pour naviguer dans certains espaces de notre site. Tel serait le cas si vous tentiez d’accéder à nos contenus ou services qui nécessitent de vous identifier. Tel serait également le cas lorsque nous ne pourrions pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par votre terminal, ses paramètres de langue et d’affichage ou le pays depuis lequel votre terminal semble connecté à Internet. Le cas échéant, nous déclinons toute responsabilité pour les conséquences liées au fonctionnement dégradé de nos services résultant de l’impossibilité pour nous d’enregistrer ou de consulter les cookies nécessaires à leur fonctionnement et que vous auriez refusés ou supprimés.</p>

        <h2>COMMENT EXERCER VOS CHOIX, SELON LE NAVIGATEUR QUE VOUS UTILISEZ ?</h2>
        <p>Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies. Pour Internet Explorer™ : ouvrez le menu « Outils », puis sélectionnez « Options internet » ; cliquez sur l’onglet « Confidentialité » puis choisissez le niveau souhaité. Pour Firefox™ : ouvrez le menu « Outils », puis sélectionnez « Options » ; cliquez sur l’onglet « Vie privée » puis choisissez les options souhaitées. Pour Chrome™ : ouvrez le menu de configuration (logo clé à molettes), puis sélectionnez « Paramètres » ; cliquez sur « Paramètres avancés » puis sur « Paramètres de contenu », puis choisissez les options souhaitées. Pour Safari™ : choisissez « Safari `&gt;` Préférences » puis cliquez sur « Sécurité » ; cliquez sur « Afficher les cookies » puis choisissez les options souhaitées. Pour Opera™ : ouvrez le menu « Outils », puis sélectionnez « Préférences » ; cliquez sur l’onglet « Avancé », puis dans la rubrique « Cookies » cliquez sur « Gérer les cookies » ; choisissez les options souhaitées.</p>
    
    </div>
    )
}


export default ConditionsGenerales;
            