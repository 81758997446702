import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Contact from './pages/contact-us';
import Home from './pages/home';
import AboutUs from './pages/about-us';
import Appartements from './pages/appartements';
import NoPage from './pages/nopage';
import LoginAdmin from './pages/admin/login-admin';
import AuthGuard from './_auth/AuthGuard';
import AdminDashboard from './pages/admin/admin-dashboard';
import ConditionsGenerales from './pages/conditions-generales';
import Layout from './layout/Layout';
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./languages/en_langage.json')
      },
      fr: {
        translation: require('./languages/fr_langage.json')
      }
    },
    lng: localStorage.getItem('language') || "fr",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false
    }
  });

export default function App() {
    return (
        <BrowserRouter>
        <Routes>
            <Route element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/appartements" element={<Appartements />} />
                <Route path="/conditions-generales" element={<ConditionsGenerales />} />
                <Route path="/login-admin" element={<LoginAdmin />} />
                <Route path="/admin-dashboard" element={ 
                    <AuthGuard>
                        <AdminDashboard />
                    </AuthGuard>
                } />
                <Route path="*" element={<NoPage />} />
            </Route>
        </Routes>
        </BrowserRouter>
    );
}