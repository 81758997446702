import React from 'react';
import './about-us.css';
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {

    return (
        <div>
            <Helmet>
                <title>À propos de nous</title>
                <meta name="description" content="Découvrez Start 2 Grow : La solution idéale pour trouver votre logement équipée sur Nantes !" />
                <link rel="canonical" href="https://conciergerielocationnantes.com/about-us" />
            </Helmet>
            <h1 className='text-title'>Découvrez Start 2 Grow : La solution idéale pour trouver votre logement équipée sur Nantes !</h1>
            <p className='text'>
                Conciergerie 100% gratuite pour le locataire.<br/><br/>

                Start 2 Grow, c'est bien plus qu'une simple plateforme de location. C'est une équipe de professionnels dévoués qui prend en charge tous les aspects de la location de votre logement, de la création et la mise en avant de votre annonce jusqu'à l'accueil et le service aux voyageurs.

                <br/><br/>Nos services comprennent :

                <br/><br/>- Communication et réservation 6 jours sur 7

                <br/>-  Accueil et service aux voyageurs

                <br/>- Ménage, blanchisserie

                <br/><br/>En tant que locataire, vous bénéficiez de nos services de conciergerie gratuits sur Nantes, pour vous assurer un séjour sans souci. Que ce soit pour des questions, des besoins de dernière minute ou des recommandations locales, notre équipe est là pour vous accompagner.

                <br/><br/>Nos services comprennent :

                <br/><br/>- Assistance dans la recherche et la réservation de votre logement

                <br/>- Accueil chaleureux à votre arrivée

                <br/>- Service de ménage professionnel pour un séjour impeccable

                <br/>- Assistance tout au long de votre séjour pour répondre à vos besoins

                <br/><br/>Avec Start 2 Grow, votre confort et votre satisfaction sont notre priorité. Contactez-nous dès aujourd'hui pour trouver votre prochain logement et profiter de notre service de conciergerie gratuit !

                <br/><br/>Bienvenue chez Start 2 Grow, où la location devient une expérience agréable et sans tracas.
            </p>
            <br/>
            <br/>
            <h2 className='video-container-title'>Avis clients :</h2>
            <div className='video-container'>
                <div className='video-row'>
                    <div className='video-text'>
                        <iframe src="https://www.youtube.com/embed/6JYIGclVQdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h3>Jean Michel</h3>
                        <p>Occaecat reprehenderit duis labore cupidatat aliqua anim id tempor nostrud laborum id ullamco nostrud.</p>
                    </div>
                    <div className='video-text'>
                        <iframe  src="https://www.youtube.com/embed/6JYIGclVQdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h3>Jean Michel</h3>
                        <p>Occaecat reprehenderit duis labore cupidatat aliqua anim id tempor nostrud laborum id ullamco nostrud.</p>
                    </div>
                </div>
                <div className='video-row'>
                    <div className='video-text'>
                        <iframe  src="https://www.youtube.com/embed/6JYIGclVQdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h3>Jean Michel</h3>
                        <p>Occaecat reprehenderit duis labore cupidatat aliqua anim id tempor nostrud laborum id ullamco nostrud.</p>
                    </div>
                    <div className='video-text'>
                        <iframe  src="https://www.youtube.com/embed/6JYIGclVQdw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <h3>Jean Michel</h3>
                        <p>Occaecat reprehenderit duis labore cupidatat aliqua anim id tempor nostrud laborum id ullamco nostrud.</p>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default AboutUs;