import React, { useEffect } from "react";

import "./BackPMenu.css";

const BackPMenu = (props) => {
    const handleBackPMenuClick = () => {
        props.actionProvider.handleDefaultMenuClick();
    };

    useEffect(() => {
        handleBackPMenuClick();
    }, []);

    return (
        <div className="back-pmenu-container">
        </div>
    );
}

export default BackPMenu;