import React, { useEffect, useState, useCallback } from 'react';
import { accountService } from '../../_services/account.service';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./admin-dashboard.css";

const AdminDashboard = () => {
    let value = {
        type: 'Louer',
        title: '',
        description: '',
        address: '',
        code_postal: '',
        ville: '',
        price: '',
        surface_habitable: '',
        surface_boutin: '',
        id_image_folder: '',
        nb_etages: '',
        nb_pieces: '',
        nb_chambres: '',
        nb_salle_deau: '',
        nb_wc: '',
        cuisine: '',
        assainissement: '',
        chauffage: '',
        loyer: '',
        charges: '',
        garantie: '',
        meuble: false,
        cave: false,
        parking: false
    }
    let Navigate = useNavigate();

    const [options, setOptions] = useState(1);
    const [credentials, setCredentials] = useState(value);
    const [listappartement, setlistappartement] = useState([]);
    const [selectedAppartement, setSelectedAppartement] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const fetchAppartements = useCallback(() => {
        setIsLoading(true);
        axios.get('https://api.conciergerielocationnantes.com/api/admin/getappartements', {
            headers: { 'Authorization': `Bearer ${accountService.getToken()}` }
        })
            .then(res => {
                console.log(res);
                if (res.data.status === 'noToken') {
                    alert('Token invalide');
                    accountService.logout();
                    Navigate('/login-admin');
                } else {
                    setlistappartement(res.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    }, [Navigate]);

    useEffect(() => {
        fetchAppartements();
    }, [fetchAppartements]);

    const handleAppartementChange = (event) => {
        const selectedAppartementId = event.target.value;
        console.log(selectedAppartementId);
        console.log(listappartement);
        if (listappartement && listappartement.length > 0) {
            const selectedAppartement = listappartement.find(appartement => appartement.id == selectedAppartementId);
            if (selectedAppartement) {
                setCredentials({
                    parking: !!selectedAppartement.information[0].parking,
                    meuble: !!selectedAppartement.information[0].meuble,
                    cave: !!selectedAppartement.information[0].cave,
                });
                setSelectedAppartement(selectedAppartement);

            } else {
                console.error(`No apartment found with ID ${selectedAppartementId}`);
            }
        } else {
            console.error('listappartement is undefined or empty');
        }
    };

    

    const onChangeAddAppartement = (event) => {
        const { name, value, type, checked } = event.target;
        setCredentials(prevCredentials => ({
            ...prevCredentials,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const onChangeUpdateAppartement = (event) => {
        const { name, value, type, checked } = event.target;
        setCredentials(prevCredentials => ({
            ...prevCredentials,
            [name]: type === 'checkbox' ? checked : value
        }));
        console.log(credentials);
    };

    const handleAdd= (e) => {
        e.preventDefault();
        credentials.meuble = credentials.meuble === true;
        credentials.cave = credentials.cave === true;
        credentials.parking = credentials.parking === true;
        axios.post('https://api.conciergerielocationnantes.com/api/admin/appartement/add', credentials, {
            headers: {
                'Authorization': `Bearer ${accountService.getToken()}`
            }
        })
        .then(res => {
            if (res.data.status === true) {
                alert('Appartement ajouté avec succès');
                setCredentials(value);
                fetchAppartements();
            } else if (res.data.status === false) {
                alert('Erreur lors de l\'ajout de l\'appartement');
            }else if (res.data.status === 'noToken') {
                alert('Token invalide');
                accountService.logout();
                Navigate('/login-admin');
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
    
    const handleUpdate = (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return; 
        }
        setIsSubmitting(true);
        const data = {
            id: selectedAppartement.id,
            type: credentials.type || selectedAppartement.type,
            title: credentials.title || selectedAppartement.titre,
            description: credentials.description || selectedAppartement.description,
            address: credentials.address || selectedAppartement.adresse,
            code_postal: credentials.code_postal || selectedAppartement.code_postal,
            ville: credentials.ville || selectedAppartement.ville,
            price: credentials.price || selectedAppartement.price,
            surface_habitable: credentials.surface_habitable || selectedAppartement.surface_habitable,
            surface_boutin: credentials.surface_boutin || selectedAppartement.surface_boutin,
            id_image_folder: credentials.id_image_folder || selectedAppartement.id_image_folder,
            nb_etages: credentials.nb_etages || selectedAppartement.caracteristiques[0].nb_etages,
            nb_pieces: credentials.nb_pieces || selectedAppartement.caracteristiques[0].nb_pieces,
            nb_chambres: credentials.nb_chambres || selectedAppartement.caracteristiques[0].nb_chambres,
            nb_salle_deau: credentials.nb_salle_deau || selectedAppartement.caracteristiques[0].nb_salle_deau,
            nb_wc: credentials.nb_wc || selectedAppartement.caracteristiques[0].nb_wc,
            cuisine: credentials.cuisine || selectedAppartement.caracteristiques[0].cuisine,
            assainissement: credentials.assainissement || selectedAppartement.caracteristiques[0].assainissement,
            chauffage: credentials.chauffage || selectedAppartement.caracteristiques[0].chauffage,
            loyer: credentials.loyer || selectedAppartement.information[0].loyer,
            charges: credentials.charges || selectedAppartement.information[0].charges,
            garantie: credentials.garantie || selectedAppartement.information[0].garantie,
            meuble: credentials.meuble === true || selectedAppartement.information[0].meuble === true,
            cave: credentials.cave === true || selectedAppartement.information[0].cave === true,
            parking: credentials.parking === true || selectedAppartement.information[0].parking === true
        }


        axios.post('https://api.conciergerielocationnantes.com/api/admin/appartement/update', data, {
            headers: { 'Authorization': `Bearer ${accountService.getToken()}` }
        })
        .then(res => {
            console.log(res);
            if (res.data.status === true) {
                alert('Appartement modifié avec succès');
                setCredentials(value);
                fetchAppartements();
                setOptions(0);
            } else if (res.data.status === false) {
                alert('Erreur lors de la modification de l\'appartement');
            }else if (res.data.status === 'noToken') {
                alert('Token invalide');
                accountService.logout();
                Navigate('/login-admin');
            }
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    }

    const handleDeleteAppartement = (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return; 
        }
        setIsSubmitting(true);
        axios.post('https://api.conciergerielocationnantes.com/api/admin/appartement/delete', {id: selectedAppartement.id}, {
            headers: { 'Authorization': `Bearer ${accountService.getToken()}` }
        })
        .then(res => {
            console.log(res);
            if (res.data.status === true) {
                alert('Appartement supprimé avec succès');
                setCredentials(value);
                fetchAppartements();
                setOptions(0);
            } else if (res.data.status === false) {
                alert('Erreur lors de la suppression de l\'appartement');
            }else if (res.data.status === 'noToken') {
                alert('Token invalide');
                accountService.logout();
                Navigate('/login-admin');
            }
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setIsSubmitting(false);
        });
    }
        

    return (
        <div className="Main-Content-dasboard">

            <div className="admin-header">
                <h1>Panel Admin</h1>
                <button className="btn-logout" onClick={() => {accountService.logout(); Navigate('/login-admin');}}>Déconnexion</button>
            </div>

            <div className="admin-dashboard">

                <button className="btn-admin" onClick={() => setOptions(1)}>Ajouter un appartement</button>
                {!isLoading && 
                    <React.Fragment>
                        <button className="btn-admin" onClick={() => {setOptions(2); handleAppartementChange({target: {value: listappartement[0].id}})}} >Modifier un appartement</button>
                        <button className="btn-admin" onClick={() => setOptions(3)}>Supprimer un appartement</button>
                    </React.Fragment>
                }
            </div>
            
            <div className="admin-dashboard-Content">
            {options === 1 && (
                <div className='admin-dashboard-addAppartement'>
                    <h2>Ajouter un Appartement</h2>
                    <br/>
                    <form className='form-addAppartement' onSubmit={handleAdd}>
                        <div className="form-group">
                            <label>Type:</label>
                            <select name="type" value={credentials.type} onChange={onChangeAddAppartement}>
                                <option value="Louer">Louer</option>
                                <option value="Acheter">Acheter</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Titre:</label>
                            <input type="text" name="title" value={credentials.title} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Description:</label>
                            <textarea name="description" value={credentials.description} onChange={onChangeAddAppartement}></textarea>
                        </div>

                        <div className="form-group">
                            <label>Adresse:</label>
                            <input type="text" name="address" value={credentials.address} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Code Postal:</label>
                            <input type="number" name="code_postal" value={credentials.code_postal} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Ville:</label>
                            <input type="text" name="ville" value={credentials.ville} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Prix:</label>
                            <input type="number" name="price" value={credentials.price} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Surface Habitable:</label>
                            <input type="number" name="surface_habitable" value={credentials.surface_habitable} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Surface Boutin:</label>
                            <input type="number" name="surface_boutin" value={credentials.surface_boutin} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>ID Image Folder:</label>
                            <input type="text" name="id_image_folder" value={credentials.id_image_folder} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Nombre d'Étages:</label>
                            <input type="number" name="nb_etages" value={credentials.nb_etages} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Nb de Pièces:</label>
                            <input type="number" name="nb_pieces" value={credentials.nb_pieces} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Nb de Chambres:</label>
                            <input type="number" name="nb_chambres" value={credentials.nb_chambres} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Nb de Salles d'Eau:</label>
                            <input type="number" name="nb_salle_deau" value={credentials.nb_salle_deau} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Nombre de WC:</label>
                            <input type="number" name="nb_wc" value={credentials.nb_wc} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Cuisine:</label>
                            <input type="text" name="cuisine" value={credentials.cuisine} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Assainissement:</label>
                            <input type="text" name="assainissement" value={credentials.assainissement} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Chauffage:</label>
                            <input type="text" name="chauffage" value={credentials.chauffage} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Loyer:</label>
                            <input type="number" name="loyer" value={credentials.loyer} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Charges:</label>
                            <input type="number" name="charges" value={credentials.charges} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Garantie:</label>
                            <input type="number" name="garantie" value={credentials.garantie} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Meublé:</label>
                            <input type="checkbox" name="meuble" checked={credentials.meuble} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Cave:</label>
                            <input type="checkbox" name="cave" checked={credentials.cave} onChange={onChangeAddAppartement} />
                        </div>

                        <div className="form-group">
                            <label>Parking:</label>
                            <input type="checkbox" name="parking" checked={credentials.parking} onChange={onChangeAddAppartement} />
                        </div>

                        <button className='btn-addAppartement' type='submit'>Ajouter</button>
                    </form>
                </div>
            )}
            {options === 2 && (
                <div className='admin-dashboard-updateAppartement'>
                    <h2>Modifier un Appartement</h2>
                    <br/>
                    <div className="form-group">
                        <label>Choisir un Appartement:</label>
                        <select name="appartement" value={credentials.appartement} onChange={handleAppartementChange} >
                            {listappartement.map((appartement) => {
                                return <option key={appartement.id} value={appartement.id}>{appartement.titre + ' - ' + appartement.adresse}</option>
                            })}
                        </select>

                        {setSelectedAppartement != null && ( 
                        <form className='form-updateAppartement'>
                            <div className="form-group">
                                <label>Type:</label>
                                <select name="type" value={credentials.type} placeholder={selectedAppartement.type} onChange={onChangeUpdateAppartement}>
                                    <option value="Louer">Louer</option>
                                    <option value="Acheter">Acheter</option>
                                </select>
                            </div>
    
                            <div className="form-group">
                                <label>Titre:</label>
                                <input type="text" name="title" value={credentials.title} placeholder={selectedAppartement.titre} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Description:</label>
                                <textarea name="description" value={credentials.description} placeholder={selectedAppartement.description} onChange={onChangeUpdateAppartement}></textarea>
                            </div>
    
                            <div className="form-group">
                                <label>Adresse:</label>
                                <input type="text" name="address" value={credentials.address} placeholder={selectedAppartement.adresse} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Code Postal:</label>
                                <input type="text" name="code_postal" value={credentials.code_postal} placeholder={selectedAppartement.code_postal} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Ville:</label>
                                <input type="text" name="ville" value={credentials.ville} placeholder={selectedAppartement.ville} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Prix:</label>
                                <input type="number" name="price" value={credentials.price} placeholder={selectedAppartement.price} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Surface Habitable:</label>
                                <input type="number" name="surface_habitable" value={credentials.surface_habitable} placeholder={selectedAppartement.surface_habitable} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Surface Boutin:</label>
                                <input type="number" name="surface_boutin" value={credentials.surface_boutin} placeholder={selectedAppartement.surface_boutin} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>ID Image Folder:</label>
                                <input type="text" name="id_image_folder" value={credentials.id_image_folder} placeholder={selectedAppartement.id_image_folder} onChange={onChangeUpdateAppartement} />
                            </div>
    
                            <div className="form-group">
                                <label>Nombre d'Étages:</label>
                                <input type="number" name="nb_etages" value={credentials.nb_etages} placeholder={selectedAppartement.caracteristiques[0].nb_etages} onChange={onChangeUpdateAppartement} />

                            </div>

                            <div className="form-group">
                                <label>Nb de Pièces:</label>
                                <input type="number" name="nb_pieces" value={credentials.nb_pieces} placeholder={selectedAppartement.caracteristiques[0].nb_pieces} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Nb de Chambres:</label>
                                <input type="number" name="nb_chambres" value={credentials.nb_chambres} placeholder={selectedAppartement.caracteristiques[0].nb_chambres} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Nb de Salles d'Eau:</label>
                                <input type="number" name="nb_salle_deau" value={credentials.nb_salle_deau} placeholder={selectedAppartement.caracteristiques[0].nb_salle_deau} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Nombre de WC:</label>
                                <input type="number" name="nb_wc" value={credentials.nb_wc} placeholder={selectedAppartement.caracteristiques[0].nb_wc} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Cuisine:</label>
                                <input type="text" name="cuisine" value={credentials.cuisine} placeholder={selectedAppartement.caracteristiques[0].cuisine} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Assainissement:</label>
                                <input type="text" name="assainissement" value={credentials.assainissement} placeholder={selectedAppartement.caracteristiques[0].assainissement} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Chauffage:</label>
                                <input type="text" name="chauffage" value={credentials.chauffage} placeholder={selectedAppartement.caracteristiques[0].chauffage} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Loyer:</label>
                                <input type="number" name="loyer" value={credentials.loyer} placeholder={selectedAppartement.information[0].loyer} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Charges:</label>
                                <input type="number" name="charges" value={credentials.charges} placeholder={selectedAppartement.information[0].charges} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Garantie:</label>
                                <input type="number" name="garantie" value={credentials.garantie} placeholder={selectedAppartement.information[0].garantie} onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Meublé:</label>
                                <input type="checkbox" name="meuble" checked={credentials.meuble}  onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Cave:</label>
                                <input type="checkbox" name="cave" checked={credentials.cave}  onChange={onChangeUpdateAppartement} />
                            </div>

                            <div className="form-group">
                                <label>Parking:</label>
                                <input type="checkbox" name="parking" checked={credentials.parking}  onChange={onChangeUpdateAppartement} />
                            </div>

                        </form>
                        )}
                    </div>
                    <button className='btn-updateAppartement' onClick={handleUpdate}>Modifier</button> 
                </div>
            )}

            {options === 3 && (
                <div className='admin-dashboard-deleteAppartement'>
                    <h2>Supprimer un Appartement</h2>
                    <br/>
                    <div className="form-group-deleteAppartement">
                        <label>Choisir un Appartement :</label>
                        <select name="appartement" value={credentials.appartement} onChange={handleAppartementChange} >
                            {listappartement.map((appartement) => {
                                return <option key={appartement.id} value={appartement.id}>{appartement.titre + ' - ' + appartement.adresse}</option>
                            })}
                        </select>
                    </div>
                    <button className='btn-deleteAppartement' onClick={handleDeleteAppartement}>Supprimer</button>
                </div>
            )}
            </div>

        </div>
    );
}

export default AdminDashboard;
