import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { accountService } from '../../_services/account.service';

import "./login-admin.css";


function LoginAdmin() {
    let Navigate = useNavigate();
    const [credentials, setCredentials] = useState({ identifiant: '', password: '' });
    const [error, setError] = useState(null);

    const onChange = (e) => {
        setError(null);
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (credentials.identifiant === '' || credentials.password === '') {
            alert('Veuillez remplir les champs');
            return;
        }
        console.log(credentials);
        axios.post('https://api.conciergerielocationnantes.com/api/auth/login-admin', credentials)
            .then(res => {
                if (res.data.status) {
                    console.log(res.data.token);
                    accountService.saveToken(res.data.token);
                    Navigate('/admin-dashboard');
                }else {
                    setError('Identifiant ou mot de passe incorrect');
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    


    return (
        <div className="Main-Content">
            <div className="login">
                <h2 className="login-title">Login Admin :</h2>
                <form className='form-login'>
                    <div className="form-group">
                        <label>Identifiant:</label>
                        <input type="text" name="identifiant" value={credentials.identifiant} onChange={onChange} />
                    </div>
                    <div className="form-group">
                        <label>Mot de passe:</label>
                        <input type="password" name="password" value={credentials.password} onChange={onChange} />
                    </div>
                    <button className='btn-login' type='submit' onClick={handleSubmit}>Se connecter</button>
                    
                    <br/>
                    <h5 style={{color: 'red'}}>{error}</h5>
                </form>
            </div>
        </div>
    );
}

export default LoginAdmin;