import React from 'react';

import './Contact-us.css';

const ContactUs = (props) => {
 
    const handleDefaultMenuClick = () => {
        props.actionProvider.handleDefaultMenuClick();
    };

    return (
        <div className="contact-us-main-container">
            <div className="contact-us-container">
                <p className="contact-us-text"> {props.Text} </p>
            </div>
            <div className="contact-us-btn-container">
                <a href="https://www.instagram.com/conciergerie_stg/" target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: 'white'}}><button className="contact-us-button" >Notre instagram</button></a>
                <button className="contact-us-button" onClick={handleDefaultMenuClick}>Menu principal</button>
            </div>
        </div>
    );
}

export default ContactUs;
