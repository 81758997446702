import React from "react";
import { Outlet,useLocation  } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import ChatBot from "../components/chatbot/chatbot";


const Layout = () => {
    const location = useLocation();

    const excludeFooterPages = ['/appartements', '/admin-dashboard', '/chatbot'];
    const excludeHeaderPages = ['/chatbot'];
    const shouldExcludeFooter = excludeFooterPages.includes(location.pathname);

    return (
        <div>
            {!excludeHeaderPages.includes(location.pathname) && <Header />}
            <Outlet />
            <ChatBot    />
            {!shouldExcludeFooter && <Footer />}
        </div>
    );
};


export default Layout;